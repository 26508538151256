import React, { useState, useContext } from "react";
import { TextField, Box, Button, MenuItem } from "@mui/material";
import { InputMask } from "primereact/inputmask";
import { AlertContext } from "../../context/AlertContext";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomerContext } from "../../context/CustomerContext";
import { LoggedContext } from "../../context/LoggedContext";
import { LoadingContext } from "../../context/LoadingContext";
import { Alert } from "@mui/material";

const type = [
  {
    value: "Individual",
    label: "Individual",
  },
  {
    value: "Company",
    label: "Company",
  },
];

const CreateCustomer = () => {
  const [displayName, setDisplayName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [typeCustomer, setType] = useState("");
  const nav = useNavigate();
  const { alert, setAlert } = useContext(AlertContext);
  const { auth, setAuth } = useContext(LoggedContext);
  const { customer, setCustomer } = useContext(CustomerContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token");

  const postData = async (data) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${`${process.env.REACT_APP_API_URL}/customer`}`,
        {
          method: `${"POST"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        if (
          errorResponse.message === "jwt expired" ||
          errorResponse.message === "jwt malformed"
        ) {
          console.log("Token expired");
          setAuth({ token: false });
          setLoading(false);
          nav("/signin");
          return;
        }
        throw new Error(`${errorResponse.message}`);
      }
      const responseData = await response.json();

      setAlert({
        open: true,
        msg: "Successfully Add Unit !",
        severity: "success",
      });
      setLoading(false);
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      setCustomer([responseData, ...customer]);

      nav("/customer");
    } catch (error) {
      console.error("An error occurred:", error.message);
      setAlert({ open: true, msg: error.message, severity: "error" });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      displayName,
      typeCustomer,
      phone,
      address,
    };

    postData(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginTop: 10,
        width: "100%",
        height: "100vh",
      }}
    >
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        sx={{
          width: "95%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Customer Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        <TextField
          select
          label="Customer Type"
          //   defaultValue="Company"
          onChange={(e) => setType(e.target.value)}
          value={typeCustomer}
          sx={{ textAlign: "left" }}
        >
          {type.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <InputMask
          value={phone}
          required
          onChange={(e) => setPhone(e.target.value)}
          mask="62?999999999999"
          placeholder="62"
        ></InputMask>

        <TextField
          required
          label="Address"
          multiline
          rows={4}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Button variant="contained" type="submit" sx={{ width: 50 }}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateCustomer;

import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerContext } from "../../context/CustomerContext";
import { LoggedContext } from "../../context/LoggedContext";
import { LoadingContext } from "../../context/LoadingContext";
const FetchCustomer = () => {
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const { loading, setLoading } = useContext(LoadingContext);
  const { logged, setLogged } = useContext(LoggedContext);
  const { customer, setCustomer } = useContext(CustomerContext);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await fetch(`${process.env.REACT_APP_API_URL}/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setLoading(false);
        setCustomer(response.cust);
      } else if (!data.ok) {
        if (
          response.message == "jwt expired" ||
          response.message == "jwt malformed"
        ) {
          console.log("error");
          setLogged({ token: false, ...logged });
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);
};

export default FetchCustomer;

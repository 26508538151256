import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Alert } from "@mui/material";
import { ReceiptContext } from "../../context/ReceiptContext";
import { LoadingContext } from "../../context/LoadingContext";
import { AlertContext } from "../../context/AlertContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FetchReceipt from "./FetchReceipt";

const columns = [
  {
    field: "receiptNo",
    headerName: "No Receipt",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <Link style={{ color: "#000" }} to={`detail/${params.row._id}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 150,
    editable: true,
    valueGetter: (params) => {
      return params.row.unit.displayName;
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 150,
    editable: true,
    valueGetter: (params) => {
      return params.row.customer.displayName;
    },
  },
  {
    field: "total",
    headerName: "Total",
    width: 150,
    editable: true,
    valueGetter: (params) => {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(params.value);
    },
  },
  {
    field: "tglTr",
    headerName: "Transaction Date",
    width: 150,
    type: "date",
    editable: true,
    valueGetter: (params) => {
      return new Date(params.value);
    },
  },
  {
    field: "jamTr",
    headerName: "Transaction Time",
    width: 150,
    editable: true,
    valueGetter: (params) => {
      const date = new Date(params.row.tglTr);
      const timeString = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      return timeString;
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    type: "date",
    editable: true,
    valueGetter: (params) => {
      return new Date(params.value);
    },
  },
  {
    field: "createdTime",
    headerName: "Created Time",
    width: 150,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt);
      const timeString = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      return timeString;
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated Date",
    type: "date",
    width: 150,
    valueGetter: (params) => {
      return new Date(params.value);
    },
  },
  {
    field: "updatedTime",
    headerName: "Updated Time",
    // type: "time",
    width: 150,
    valueGetter: (params) => {
      const date = new Date(params.row.updatedAt);
      const timeString = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      return timeString;
    },
  },
];
function getRowId(row) {
  return row._id;
}

const Receipt = () => {
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const [dialog, setDialog] = useState(false);
  const { loading, setLoading } = useContext(LoadingContext);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { alert, setAlert } = useContext(AlertContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (rowSelectionModel.length >= 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [rowSelectionModel]);

  const onDeleteClick = () => {
    rowSelectionModel.map(async (item) => {
      await handleDelete(item);
    });
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/receipt/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(`${data.message}`);
      } else {
        setLoading(false);
        let newReceipt = receipt.filter(
          (d) => !rowSelectionModel.includes(d._id)
        );
        setReceipt(newReceipt);
        setAlert({
          open: true,
          msg: "Successfully Delete Receipt !",
          severity: "success",
        });
        setTimeout(() => {
          setAlert({ open: false });
        }, 4000);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
      setAlert({
        open: true,
        msg: `${error}`,
        severity: "error",
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
    }
    setDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginTop: 10,
        width: "100%",
        height: "100vh",
      }}
    >
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <FetchReceipt />
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Unit"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete unit ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Disagree</Button>
          <Button onClick={onDeleteClick} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: "95%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Link to={"/receiptform"}>
            <Button variant="outlined" sx={{ width: 70 }}>
              New
            </Button>
          </Link>
          <Button
            variant="outlined"
            color="error"
            sx={{ width: 70 }}
            disabled={isDisabled}
            onClick={() => {
              setDialog(true);
            }}
          >
            Delete
          </Button>
        </Box>
        {receipt && (
          <DataGrid
            rows={receipt}
            getRowId={getRowId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            sx={{
              maxWidth: 1000,
            }}
            pageSizeOptions={[10]}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            checkboxSelection
          />
        )}
      </Box>
    </Box>
  );
};

export default Receipt;

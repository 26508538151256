import React, { useContext, useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Alert } from "@mui/material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LoggedContext } from "../../context/LoggedContext";
import { AlertContext } from "../../context/AlertContext";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Rio Zakirahman
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

const SignIn = () => {
  const { alert, setAlert } = useContext(AlertContext);
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { auth, setAuth } = useContext(LoggedContext);
  const nav = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/profile`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          setIsOpen(false);
          setAuth({ token: true });
        } else {
          setIsOpen(false);
          console.log("Wrong token");
          setAuth({ token: null });
        }
      } catch (error) {
        console.error("Error fetching token:", error);
        setIsOpen(false);
        setAuth({ token: null });
      }
    };

    if (token) {
      setIsOpen(true);
      fetchToken();
    } else {
      setIsOpen(false);
      setAuth({ token: null });
    }
  }, [token, setAuth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsOpen(true);
    const data = {
      username,
      password,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dataLogin = await response.json();

    if (response.ok) {
      setAuth({ token: true });
      setIsOpen(false);
      setAlert({ open: true, severity: "success", msg: "Successfully login!" });
      localStorage.setItem("token", dataLogin.token);
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      nav("/");
    } else if (!response.ok) {
      setIsOpen(false);
      setAlert({
        open: true,
        severity: "error",
        msg: `${dataLogin.message}`,
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
    }
  };
  useEffect(() => {
    if (auth.token) {
      nav("/");
    }
  }, [auth]);
  return (
    <ThemeProvider theme={defaultTheme}>
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;

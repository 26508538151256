import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, Tabs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Button,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  ListSubheader,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingContext } from "../../context/LoadingContext";
import { LoggedContext } from "../../context/LoggedContext";
import { Alert } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
import { CustomerContext } from "../../context/CustomerContext";
import { UnitContext } from "../../context/UnitContext";
import { ReceiptContext } from "../../context/ReceiptContext";
import { InputNumber } from "primereact/inputnumber";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Textarea } from "@mui/joy";

const ReceiptDetail = () => {
  let { id } = useParams();
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const [custData, setCustData] = useState("");
  const [unitData, setUnitData] = useState("");
  const [phone, setPhone] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [custName, setCustName] = useState("");
  const today = new Date();
  const todayISOString = today.toISOString();
  const [tglTr, setTglTr] = useState(dayjs(todayISOString));
  const [description, setDescription] = useState("");
  const [total, setTotal] = useState("");
  const { logged, setLogged } = useContext(LoggedContext);
  const { alert, setAlert } = useContext(AlertContext);
  const { customer, setCustomer } = useContext(CustomerContext);
  const { unit, setUnit } = useContext(UnitContext);
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const [searchText, setSearchText] = useState("");
  const [searchCustText, setSearchCustText] = useState("");
  const displayedUnitOptions = useMemo(
    () => unit.filter((option) => containsText(option.displayName, searchText)),
    [searchText]
  );

  const displayedCustOptions = useMemo(
    () =>
      customer.filter((option) =>
        containsText(option.displayName, searchCustText)
      ),
    [searchCustText]
  );

  useEffect(() => {
    setTotal(unitData.rentPrice);
  }, [unitData.rentPrice]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}/receipt/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await data.json();
      if (data.ok) {
        setLoading(false);
        setUnitData({
          id: response.unit._id,
          rentPrice: response.total,
          ...unitData,
        });
        setReceiptNo(response.receiptNo);
        setPhone(response.customer.phone);
        setCustName(response.customer.displayName);
        setCustData(response.customer._id);
        setDescription(response.description);
        setTotal(response.total);
        console.log(response.tglTr);
        setTglTr(dayjs(response.tglTr));
      } else if (!data.ok) {
        if (
          response.message == "jwt expired" ||
          response.message == "jwt malformed"
        ) {
          console.log("error");
          setLogged({ token: false, ...logged });
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);

  const update = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/receipt/${id}`,
        {
          method: `PATCH`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            unit: unitData.id,
            customer: custData,
            description,
            total: unitData.rentPrice,
            tglTr,
          }),
        }
      );

      if (!response.ok) {
        setLoading(false);
        const errorResponse = await response.json();
        if (
          errorResponse.message === "jwt expired" ||
          errorResponse.message === "jwt malformed"
        ) {
          console.log("Token expired");
          setLogged({ token: false });
          nav("/login");
          return;
        }
        throw new Error(`${errorResponse.message}`);
      }
      const responseData = await response.json();
      console.log(responseData);

      const filteredReceipt = receipt.map((c) => {
        if (c._id === responseData._id) {
          return responseData;
        }
        return c;
      });
      setReceipt(filteredReceipt);
      setLoading(false);
      setAlert({
        open: true,
        severity: "success",
        msg: "Successfully Update Receipt",
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);

      setUnitData({
        id: responseData.unit._id,
        rentPrice: responseData.total,
      });
      setCustData(responseData.customer._id);
      setDescription(responseData.description);
      setTotal(responseData.total);
      setTglTr(dayjs(responseData.tglTr));
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error.message);
      setAlert({
        open: true,
        severity: "error",
        msg: `${error.message}`,
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginTop: 10,
        width: "100%",
        height: "100vh",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          update(id);
        }}
        sx={{
          width: "95%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <Link
            target="_blank"
            to={`https://api.whatsapp.com/send?phone=${phone}&text=Halo ${custName}, terima kasih atas kepercayaan Anda dengan Unit kami. Berikut adalah kwitansi ${receiptNo} sebesar ${new Intl.NumberFormat(
              "id-ID",
              {
                style: "currency",
                currency: "IDR",
              }
            ).format(total)} Silakan lihat detail kwitansi di sini ${
              process.env.REACT_APP_API_URL
            }/receiptPDF?id=${id} ...`}
          >
            <Button
              variant="contained"
              color="success"
              sx={{ width: 50, marginBottom: 2 }}
            >
              <WhatsAppIcon />
            </Button>
          </Link>
          <Link
            to={`${process.env.REACT_APP_API_URL}/receiptPDF?id=${id}`}
            target="_blank"
          >
            <Button
              variant="contained"
              color="error"
              sx={{ width: 50, marginBottom: 2 }}
            >
              <PictureAsPdfIcon />
            </Button>
          </Link>
        </div>

        <FormControl>
          <InputLabel id="search-select-label">Unit</InputLabel>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={unitData}
            label="Options"
            required
            sx={{ textAlign: "left" }}
            onChange={(e) => setUnitData(e.target.value)}
            onClose={() => setSearchText("")}
            // This prevents rendering empty string in Select's value
            // if search text would exclude currently selected option.
            renderValue={() => {
              const selectedOption = displayedUnitOptions.find(
                (option) => option._id === unitData.id
              );
              return selectedOption ? selectedOption.displayName : "";
            }}
          >
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedUnitOptions.map((option, i) => (
              <MenuItem
                key={option._id}
                value={{ id: option._id, rentPrice: option.rentPrice }}
              >
                {option.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <>
          <label style={{ textAlign: "Left" }}>Rent Price</label>
          <InputNumber
            value={unitData && unitData.rentPrice}
            onChange={(e) => setUnitData({ ...unitData, rentPrice: e.value })}
            mode="currency"
            currency="IDR"
            locale="de-DE"
          />
        </>

        <FormControl>
          <InputLabel id="search-select-label">Customer</InputLabel>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={custData}
            label="Options"
            required
            sx={{ textAlign: "left" }}
            onChange={(e) => setCustData(e.target.value)}
            onClose={() => setSearchCustText("")}
            // This prevents rendering empty string in Select's value
            // if search text would exclude currently selected option.
            renderValue={() => {
              const selectedOption = displayedCustOptions.find(
                (option) => option._id === custData
              );
              return selectedOption ? selectedOption.displayName : "";
            }}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchCustText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedCustOptions.map((option, i) => (
              <MenuItem key={option._id} value={option._id}>
                {option.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Textarea
            required
            minRows={4}
            variant="outlined"
            placeholder="Description"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            endDecorator={
              <Typography level="body-xs" sx={{ ml: "auto" }}>
                {description.length} character(s)
              </Typography>
            }
          />
        </FormControl>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                label="Tanggal Transaksi"
                required
                value={tglTr}
                onChange={(e) => {
                  if (e && e.$d !== null && e.$d !== undefined) {
                    setTglTr(e.$d);
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <label style={{ textAlign: "Left" }}>Total</label>
          <InputNumber
            value={total}
            disabled
            style={{ width: "100%" }}
            mode="currency"
            currency="IDR"
            locale="de-DE"
          />
        </div>
        <Button variant="contained" sx={{ width: 50 }} type="submit">
          Save
        </Button>
      </Box>
      <Box>
        <Tabs></Tabs>
      </Box>
    </Box>
  );
};

export default ReceiptDetail;

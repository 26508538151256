import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReceiptContext } from "../../context/ReceiptContext";
import { LoggedContext } from "../../context/LoggedContext";
import { LoadingContext } from "../../context/LoadingContext";
const FetchReceipt = () => {
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const { loading, setLoading } = useContext(LoadingContext);
  const { logged, setLogged } = useContext(LoggedContext);
  const { receipt, setReceipt } = useContext(ReceiptContext);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await fetch(`${process.env.REACT_APP_API_URL}/receipt`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setLoading(false);

        setReceipt(response.receipt);
      } else if (!data.ok) {
        if (
          response.message == "jwt expired" ||
          response.message == "jwt malformed"
        ) {
          console.log("error");
          setLogged({ token: false, ...logged });
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);
};

export default FetchReceipt;

import React, { useState, useContext, useMemo } from "react";
import Textarea from "@mui/joy/Textarea";
import dayjs from "dayjs";
import {
  TextField,
  Box,
  Button,
  Select,
  MenuItem,
  ListSubheader,
  InputAdornment,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputNumber } from "primereact/inputnumber";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SearchIcon from "@mui/icons-material/Search";
import { AlertContext } from "../../context/AlertContext";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReceiptContext } from "../../context/ReceiptContext";
import { CustomerContext } from "../../context/CustomerContext";
import { LoggedContext } from "../../context/LoggedContext";
import { LoadingContext } from "../../context/LoadingContext";
import { UnitContext } from "../../context/UnitContext";
import { Alert } from "@mui/material";

const type = [
  {
    value: "Individual",
    label: "Individual",
  },
  {
    value: "Company",
    label: "Company",
  },
];

const CreateReceipt = () => {
  const { unit, setUnit } = useContext(UnitContext);
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const { customer, setCustomer } = useContext(CustomerContext);
  const [rentPrice, setRentPrice] = useState();
  const today = new Date();
  const todayISOString = today.toISOString();
  const [tglTr, setTglTr] = useState();

  const [description, setDescription] = useState("");
  const [total, setTotal] = useState("");
  const [address, setAddress] = useState("");
  const [custData, setCustData] = useState("");
  const [unitData, setUnitData] = useState({ id: "", rentPrice: 0 });
  const nav = useNavigate();
  const { alert, setAlert } = useContext(AlertContext);
  const { auth, setAuth } = useContext(LoggedContext);

  const { loading, setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token");
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const [searchText, setSearchText] = useState("");
  const [searchCustText, setSearchCustText] = useState("");
  const displayedUnitOptions = useMemo(
    () => unit.filter((option) => containsText(option.displayName, searchText)),
    [searchText]
  );

  const displayedCustOptions = useMemo(
    () =>
      customer.filter((option) =>
        containsText(option.displayName, searchCustText)
      ),
    [searchCustText]
  );

  const postData = async (data) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${`${process.env.REACT_APP_API_URL}/receipt`}`,
        {
          method: `${"POST"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        if (
          errorResponse.message === "jwt expired" ||
          errorResponse.message === "jwt malformed"
        ) {
          console.log("Token expired");
          setAuth({ token: false });
          setLoading(false);
          nav("/signin");
          return;
        }
        throw new Error(`${errorResponse.message}`);
      }
      const responseData = await response.json();

      setAlert({
        open: true,
        msg: "Successfully Add Receipt !",
        severity: "success",
      });
      setLoading(false);
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      setReceipt([responseData, ...receipt]);

      nav("/receipt");
    } catch (error) {
      console.error("An error occurred:", error.message);
      setAlert({ open: true, msg: error.message, severity: "error" });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tglTr) {
      // Handle invalid form submission (e.g., show an error message)
      return;
    }
    const data = {
      unit: unitData.id,
      customer: custData,
      description,
      total: unitData.rentPrice,
      tglTr,
    };

    postData(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginTop: 10,
        width: "100%",
        height: "100vh",
      }}
    >
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        sx={{
          width: "95%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        onSubmit={handleSubmit}
      >
        <FormControl>
          <InputLabel id="search-select-label">Unit</InputLabel>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={unitData}
            label="Options"
            required
            sx={{ textAlign: "left" }}
            onChange={(e) => setUnitData(e.target.value)}
            onClose={() => setSearchText("")}
            // This prevents rendering empty string in Select's value
            // if search text would exclude currently selected option.
            renderValue={() => {
              const selectedOption = displayedUnitOptions.find(
                (option) => option._id === unitData.id
              );
              return selectedOption ? selectedOption.displayName : "";
            }}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedUnitOptions.map((option, i) => (
              <MenuItem
                key={option._id}
                value={{ id: option._id, rentPrice: option.rentPrice }}
              >
                {option.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <>
          <label style={{ textAlign: "Left" }}>Rent Price</label>
          <InputNumber
            value={unitData && unitData.rentPrice}
            onChange={(e) => setUnitData({ ...unitData, rentPrice: e.value })}
            mode="currency"
            currency="IDR"
            locale="de-DE"
          />
        </>

        <FormControl>
          <InputLabel id="search-select-label">Customer</InputLabel>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={custData}
            label="Options"
            required
            sx={{ textAlign: "left" }}
            onChange={(e) => setCustData(e.target.value)}
            onClose={() => setSearchCustText("")}
            // This prevents rendering empty string in Select's value
            // if search text would exclude currently selected option.
            renderValue={() => {
              const selectedOption = displayedCustOptions.find(
                (option) => option._id === custData
              );
              return selectedOption ? selectedOption.displayName : "";
            }}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchCustText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedCustOptions.map((option, i) => (
              <MenuItem key={option._id} value={option._id}>
                {option.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Textarea
            required
            minRows={4}
            variant="outlined"
            placeholder="Description"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            endDecorator={
              <Typography level="body-xs" sx={{ ml: "auto" }}>
                {description.length} character(s)
              </Typography>
            }
          />
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                label="Tanggal Transaksi"
                value={tglTr}
                onChange={(e) => {
                  if (e.$d) {
                    setTglTr(e.$d);
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>

        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <label style={{ textAlign: "Left" }}>Total</label>
          <InputNumber
            value={unitData && unitData.rentPrice}
            disabled
            style={{ width: "100%" }}
            // onChange={(e) => setTotal(e.value)}
            mode="currency"
            currency="IDR"
            locale="de-DE"
          />
        </div>
        <Button variant="contained" type="submit" sx={{ width: 50 }}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateReceipt;

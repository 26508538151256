import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LoggedContextProvider } from "./context/LoggedContext";
import { AlertContextProvider } from "./context/AlertContext";
import { UnitContextProvider } from "./context/UnitContext";
import { LoadingContextProvider } from "./context/LoadingContext";
import { CustomerContextProvider } from "./context/CustomerContext";
import { ReceiptContextProvider } from "./context/ReceiptContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <LoggedContextProvider>
        <LoadingContextProvider>
          <UnitContextProvider>
            <CustomerContextProvider>
              <ReceiptContextProvider>
                <AlertContextProvider>
                  <App />
                </AlertContextProvider>
              </ReceiptContextProvider>
            </CustomerContextProvider>
          </UnitContextProvider>
        </LoadingContextProvider>
      </LoggedContextProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

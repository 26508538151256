import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import SignIn from "./components/SignIn/SignIn";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import Unit from "./components/Unit/Unit";
import { LoggedContext } from "./context/LoggedContext";
import { useContext } from "react";
import ResponsiveDrawer from "./components/Navigation/Navigation";
import UnitDetail from "./components/Unit/UnitDetail";
import CreateUnit from "./components/Unit/CreateUnit";
import Customer from "./components/Customer/Customer";
import CreateCustomer from "./components/Customer/CreateCustomer";
import CustomerDetail from "./components/Customer/CustomerDetail";
import Receipt from "./components/Receipt/Receipt";
import CreateReceipt from "./components/Receipt/CreateReceipt";
import Test from "./components/Unit/Test";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import ReceiptDetail from "./components/Receipt/ReceiptDetail";

function App() {
  const { auth, setAuth } = useContext(LoggedContext);
  return (
    <div className="App">
      <Routes>
        <Route path="/test" element={<Test />}></Route>
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/receipt"
            element={
              <ResponsiveDrawer title={"Receipt"}>
                <Receipt />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="receipt/detail/:id"
            element={
              <ResponsiveDrawer title={"Receipt"}>
                <ReceiptDetail />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="/receiptform"
            element={
              <ResponsiveDrawer title={"Receipt"}>
                <CreateReceipt />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="/"
            element={
              <ResponsiveDrawer title={"Unit"}>
                <Unit />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="/detail/:id"
            element={
              <ResponsiveDrawer title={"Unit Detail"}>
                <UnitDetail />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="/newunit"
            element={
              <ResponsiveDrawer title={"New Unit"}>
                <CreateUnit />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="/customer"
            element={
              <ResponsiveDrawer title={"Customer"}>
                <Customer />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="customer/detail/:id"
            element={
              <ResponsiveDrawer title={"Customer"}>
                <CustomerDetail />
              </ResponsiveDrawer>
            }
          ></Route>
          <Route
            path="/customerform"
            element={
              <ResponsiveDrawer title={"New Customer"}>
                <CreateCustomer />
              </ResponsiveDrawer>
            }
          ></Route>
        </Route>

        <Route path="*" element={<h1>404</h1>} />
        <Route
          path="/signin"
          element={
            auth.token == false || auth.token == null ? (
              <SignIn />
            ) : (
              <Navigate to={"/"} />
            )
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;

import { createContext, useState } from "react";
export const UnitContext = createContext({});

export function UnitContextProvider({ children }) {
  const [unit, setUnit] = useState([]);

  return (
    <UnitContext.Provider value={{ unit, setUnit }}>
      {children}
    </UnitContext.Provider>
  );
}

import { createContext, useState } from "react";
export const ReceiptContext = createContext({});

export function ReceiptContextProvider({ children }) {
  const [receipt, setReceipt] = useState([]);

  return (
    <ReceiptContext.Provider value={{ receipt, setReceipt }}>
      {children}
    </ReceiptContext.Provider>
  );
}

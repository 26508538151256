import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, Tabs } from "@mui/material";
import { Button, Backdrop, CircularProgress, MenuItem } from "@mui/material";
import { LoadingContext } from "../../context/LoadingContext";
import { LoggedContext } from "../../context/LoggedContext";
import { Alert } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
import { CustomerContext } from "../../context/CustomerContext";
import { InputMask } from "primereact/inputmask";
const type = [
  {
    value: "Individual",
    label: "Individual",
  },
  {
    value: "Company",
    label: "Company",
  },
];

const CustomerDetail = () => {
  let { id } = useParams();
  const [displayName, setDisplayName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [typeCustomer, setType] = useState("");
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const { logged, setLogged } = useContext(LoggedContext);
  const { alert, setAlert } = useContext(AlertContext);
  const { customer, setCustomer } = useContext(CustomerContext);
  const { loading, setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}/customer/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await data.json();
      if (data.ok) {
        setLoading(false);
        setDisplayName(response.displayName);
        setPhone(response.phone);
        setType(response.typeCustomer);
        setAddress(response.address);
      } else if (!data.ok) {
        if (
          response.message == "jwt expired" ||
          response.message == "jwt malformed"
        ) {
          console.log("error");
          setLogged({ token: false, ...logged });
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);

  const update = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/customer/${id}`,
        {
          method: `PATCH`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            displayName,
            address,
            phone,
            typeCustomer,
          }),
        }
      );

      if (!response.ok) {
        setLoading(false);
        const errorResponse = await response.json();
        if (
          errorResponse.message === "jwt expired" ||
          errorResponse.message === "jwt malformed"
        ) {
          console.log("Token expired");
          setLogged({ token: false });
          nav("/login");
          return;
        }
        throw new Error(`${errorResponse.message}`);
      }
      const responseData = await response.json();
      console.log(responseData);

      const filteredCust = customer.map((c) => {
        if (c._id === responseData._id) {
          return responseData;
        }
        return c;
      });
      setCustomer(filteredCust);
      setLoading(false);
      setAlert({
        open: true,
        severity: "success",
        msg: "Successfully Update Unit",
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);

      setDisplayName(responseData.displayName);
      setAddress(responseData.address);
      setPhone(responseData.phone);
      setType(responseData.typeCustomer);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error.message);
      setAlert({
        open: true,
        severity: "error",
        msg: `${error.message}`,
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginTop: 10,
        width: "100%",
        height: "100vh",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <Box
        sx={{
          width: "95%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <TextField
          required
          id="outlined-required"
          label="Customer Name"
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
        />
        <TextField
          select
          label="Customer Type"
          //   defaultValue="Company"
          onChange={(e) => setType(e.target.value)}
          value={typeCustomer}
          sx={{ textAlign: "left" }}
        >
          {type.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {phone && (
          <InputMask
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            mask="62?999999999999"
          ></InputMask>
        )}

        <TextField
          required
          id="outlined-required"
          label="Address"
          multiline
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          rows={4}
        />
        <Button
          variant="contained"
          sx={{ width: 50 }}
          onClick={() => update(id)}
        >
          Save
        </Button>
      </Box>
      <Box>
        <Tabs></Tabs>
      </Box>
    </Box>
  );
};

export default CustomerDetail;

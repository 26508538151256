import React, { useState, useContext } from "react";
import { TextField, Box, Button } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UnitContext } from "../../context/UnitContext";
import { LoggedContext } from "../../context/LoggedContext";
import { LoadingContext } from "../../context/LoadingContext";
import { Alert } from "@mui/material";
import { InputNumber } from "primereact/inputnumber";

const CreateUnit = () => {
  const [displayName, setDisplayName] = useState("");
  const [address, setAddress] = useState("");
  const [rentPrice, setRentPrice] = useState();
  const nav = useNavigate();
  const { alert, setAlert } = useContext(AlertContext);
  const { auth, setAuth } = useContext(LoggedContext);
  const { unit, setUnit } = useContext(UnitContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token");

  const postData = async (data) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${`${process.env.REACT_APP_API_URL}/unit`}`,
        {
          method: `${"POST"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        if (
          errorResponse.message === "jwt expired" ||
          errorResponse.message === "jwt malformed"
        ) {
          console.log("Token expired");
          setAuth({ token: false });
          setLoading(false);
          nav("/signin");
          return;
        }
        throw new Error(`${errorResponse.message}`);
      }
      const responseData = await response.json();
      setAlert({
        open: true,
        msg: "Successfully Add Unit !",
        severity: "success",
      });
      setLoading(false);
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      setUnit([responseData, ...unit]);

      nav("/");
    } catch (error) {
      console.error("An error occurred:", error.message);
      setAlert({ open: true, msg: error.message, severity: "error" });
      setTimeout(() => {
        setAlert({ open: false });
      }, 4000);
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      displayName,
      address,
      rentPrice,
    };
    postData(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginTop: 10,
        width: "100%",
        height: "100vh",
      }}
    >
      {alert.open && (
        <Alert
          severity={alert.severity}
          sx={{ position: "absolute", top: "70px", right: 25 }}
        >
          {alert.msg}
        </Alert>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        sx={{
          width: "95%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Unit Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        <TextField
          required
          label="Address"
          multiline
          rows={4}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <InputNumber
          value={rentPrice}
          placeholder="Rent Price"
          onChange={(e) => setRentPrice(e.value)}
          mode="currency"
          currency="IDR"
          locale="de-DE"
        />
        <Button variant="contained" type="submit" sx={{ width: 50 }}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUnit;

import React, { useContext, useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { LoggedContext } from "../../context/LoggedContext";
import { Backdrop, CircularProgress } from "@mui/material";

const ProtectedRoutes = () => {
  const { auth, setAuth } = useContext(LoggedContext);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/profile`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          setLoading(false);
          setAuth({ token: true });
        } else {
          setLoading(false);
          console.log("Wrong token");
          setAuth({ token: null });
        }
      } catch (error) {
        console.error("Error fetching token:", error);
        setLoading(false);
        setAuth({ token: null });
      }
    };
    if (token) {
      setLoading(true);
      fetchToken();
    } else {
      setLoading(false);
      setAuth({ token: null });
    }
  }, [token, setAuth]);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return auth.token === true ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoutes;
